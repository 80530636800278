const header = $(`<div class="header-main">
<div class="logo"></div>
<ul>
<li class="li"><a class="a1">首页</a></li>
<li class="li"><a class="a2">关于我们</a></li>
<li class="li"><a class="a4">联系我们</a></li>
</ul>
<div class="header-tel no-tel"><i class="tel-icon"></i><p>952592</p></div>
</div>`)

$(document).ready(() => {
  let flag = false
  $('.li').hover(() => {
    $('.header-main').removeClass('header-main-hover')
    $('.header-main').addClass('header-main-hover-1')
  }, () => {

  })
  $('#app').click(() => {
    if (flag) {
      flag = false
      $('.header-main').removeClass('header-main-hover')
    }
  })
  $('.logo').click(() => {
    location.href = location.origin + '/fe/website/indexXiaoYing.html'
  })

  $('.header-tel').hover(() => {
    $('.header-tel').addClass('header-tel-active')
  }, () => {
    $('.header-tel').removeClass('header-tel-active')
  })
  $('.a1').click(() => {
    location.href = location.origin + '/fe/website/indexXiaoYing.html'
  })

  $('.a2').click(() => {
    location.href = location.origin + '/fe/website/aboutXiaoYing.html'
  })
  $('.a4').click(() => {
    location.href = location.origin + '/fe/website/contactXiaoYing.html'
  })

  $('.header-main').hover(() => {}, () => {
    $('.header-main').removeClass('header-main-hover')
    $('.header-main').removeClass('header-main-hover-1')
  })

  window.addEventListener('scroll', function () {
    const t = $('body, html').scrollTop()
    if (t > 0) {
      $('.header-main').addClass('header-main-active')
    } else {
      $('.header-main').removeClass('header-main-active')
    }
  })
})
export default header
